import { Platform, TouchableHighlight } from 'react-native';
import {
  Box,
  Center,
  Divider,
  FlatList,
  HStack,
  Image,
  Text,
  View,
  VStack,
} from 'native-base';
import dayjs from 'dayjs';
import { useEventos } from 'src/features/eventos/queries/use-eventos';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from 'src/navigator';
import { Loading } from 'src/components/loading';

export function LatestEventList() {
  const { data: eventos, isLoading } = useEventos();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  if (isLoading) {
    return (
      <Center h="100%">
        <Loading />
      </Center>
    );
  }

  return (
    <FlatList
      backgroundColor="contrastThreshold"
      mb={Platform.OS === "android" ? 12 : 16}
      data={eventos}
      bgColor={"white"}
      renderItem={({ item }: any) => (
        <TouchableHighlight
          key={item.nid}
          underlayColor="transparent"
          onPress={() => {
            navigation.navigate('evento-detail', {
              title: item.title,
              evento: {
                title: item.title,
                image_url: item.image_url,
                fecha: item.fecha,
                contenido: item.contenido,
                link: item.link,
              },
            });
          }}
        >
          {Platform.OS !== "web" ? (
            <Box shadow="1" w="95%" m="auto" my={1} borderRadius={2} backgroundColor={"white"}>
              <HStack alignItems="center" backgroundColor="white" borderRadius={2}>
                <Image
                  size="lg"
                  source={{ uri: item.thumbnail }}
                  mr="1"
                  borderTopLeftRadius="sm"
                  borderBottomLeftRadius="sm"
                  alt={item.title}
                />
                <VStack w="60%" ml="2">
                  <Text bold fontSize="16" color="#004C35">
                    {item.title}
                  </Text>
                  <Text italic>
                    {dayjs(item.fecha).format(
                      'dddd, DD [de] MMMM [de] YYYY, H:mm'
                    )}
                  </Text>
                </VStack>
              </HStack>
              <Divider />
            </Box>
          ) : (
            <Box>
              <HStack alignItems="center" p="3">
                <Image
                  size={24}
                  source={{ uri: item.thumbnail }}
                  mr="1"
                  borderRadius="sm"
                  alt={item.title}
                />
                <VStack w="70%" ml="2">
                  <Text bold fontSize="16">
                    {item.title}
                  </Text>
                  <Text italic>
                    {dayjs(item.fecha).format(
                      'dddd, DD [de] MMMM [de] YYYY, H:mm'
                    )}
                  </Text>
                </VStack>
              </HStack>
              <Divider />
            </Box>
          )}
        </TouchableHighlight>
      )}
      ListFooterComponent={<View marginBottom={'10'} />}
    />
  );
}
