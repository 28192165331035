import { Platform, TouchableHighlight } from "react-native";
import {
  Text,
  Box,
  Image,
  View,
  useMediaQuery,
  Badge,
  Center,
  Stack,
  useBreakpointValue,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "src/navigator";
import dayjs from "dayjs";
import { Entypo } from "@expo/vector-icons";

import logoMuni from "../../../../assets/mcc_logo.png";

const estados = [
  ["PENDIENTE"],
  [
    "SOLUCIONADO",
    "SOLUCIÓN FUERA DE TÉRMINO",
    "ATENDIDO",
    "INTIMADO",
    "INFORMACIÓN INCOMPLETA - REENVIAR",
  ],
  [
    "EN PROCESO",
    "CERRADO / REABIERTO",
    "REABIERTO",
    "CAMBIO RESPONSABLE",
    "INCLUIDO EN PLAN",
  ],
];

export function Reclamo(reclamos: any) {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });
  const stackDirection = useBreakpointValue({
    base: "column",
    lg: "row",
    xl: "row",
  });
  const alignBadge = useBreakpointValue({
    base: "center",
    lg: "self-start",
    xl: "self-start",
  });
  const marginTops = useBreakpointValue({
    base: "2",
    lg: 0,
    xl: 0,
  });
  const photosLenght = Boolean(reclamos.reclamos.fotos?.length);

  const handleReclamoStateTitle = (estado: string) => {
    let estadoTitle;
    let color;
    if (estados[0].includes(estado)) {
      estadoTitle = "Enviado";
      color = "gray";
    } else if (estados[1].includes(estado)) {
      estadoTitle = "Finalizado";
      color = "green";
    } else if (estados[2].includes(estado)) {
      estadoTitle = "En Proceso";
      color = "orange";
    } else {
      estadoTitle = estado;
    }

    return {
      estadoTitle,
      color,
    };
  };

  const handleReclamoState = () => {
    let badge;
    const { color, estadoTitle } = handleReclamoStateTitle(
      reclamos.reclamos.estado.toUpperCase()
    );
    badge = (
      <Badge
        colorScheme={color}
        variant="solid"
        fontWeight="bold"
        alignSelf={alignBadge}
        fontSize="16"
        textAlign="center"
        mt={marginTops}
      >
        {estadoTitle}
      </Badge>
    );

    return badge;
  };

  return (
    <View>
      <TouchableHighlight
        underlayColor="transparent"
        onPress={() => {
          navigation.navigate("reclamos-details", {
            id: reclamos.reclamos.id_contacto,
          });
        }}
      >
        <Box
          w="100%"
          my="3"
          backgroundColor={"white"}
          shadow={Platform.OS !== "web" ? 2 : 8}
          borderRadius={Platform.OS !== "web" ? "sm" : 16}
          borderWidth={Platform.OS !== "web" ? 1 : 0}
          borderColor="gray.100"
        >
          <Stack
            direction={stackDirection}
            pb="2"
            w="100%"
            justifyContent="space-evenly"
            backgroundColor="white"
            borderRadius="sm"
          >
            <Box>
              {Boolean(photosLenght) ? (
                <Image
                  size={24}
                  w={{ base: "full", lg: "32", xl: "32" }}
                  h={{ base: "56", lg: "32", xl: "32" }}
                  source={{
                    uri: `${process.env.EXPO_PUBLIC_SISTEMAS_WEB_URL}/${reclamos.reclamos.fotos?.[0]?.nombre_foto}`,
                  }}
                  fallbackSource={logoMuni}
                  ml={{ base: 0, lg: "16", xl: 0 }}
                  borderTopRadius="sm"
                  alt="reclamo image"
                />
              ) : (
                <Center>
                  <Image
                    size={24}
                    source={{
                      uri: "https://ciudaddecorrientes.gov.ar/sites/default/files/escudo_municipalidad_nuevo-01_2.png",
                    }}
                    ml={{ base: 4, lg: 0, xl: 4 }}
                    alt="reclamo image"
                  />
                </Center>
              )}
            </Box>
            <Stack
              direction={{ base: "column", lg: "row", xl: "row" }}
              w={{ base: "100%", lg: "80%", xl: "80%" }}
              mr="16"
              pt={Platform.OS !== "web" ? "1" : "12"}
              alignContent="center"
              justifyContent="space-around"
            >
              <Text
                fontWeight="semibold"
                fontSize="16"
                isTruncated
                textAlign={{ base: "center", lg: "start", xl: "start" }}
                w={{ base: "100%", lg: "35%", xl: "60%" }}
                mt={marginTops}
              >
                {reclamos.reclamos.descripcion}
              </Text>
              <Text
                fontWeight="semibold"
                fontSize="16"
                textAlign="center"
                mt={marginTops}
              >
                {dayjs(reclamos.reclamos.fecha_hora_contacto).format(
                  "DD/MM/YYYY"
                )}
              </Text>
              {Platform.OS !== "web" &&
                <Text
                  fontWeight="semibold"
                  fontSize="16"
                  isTruncated
                  textAlign={{ base: "center", lg: "start", xl: "start" }}
                  w={{ base: "100%", lg: "35%", xl: "60%" }}
                  mt={marginTops}
                >
                  {reclamos.reclamos.otro_dato_ubicacion}
                </Text>
              }
              {handleReclamoState()}
              {isSmallScreen && <Entypo name="eye" size={30} color="#004c35" />}
            </Stack>
          </Stack>
        </Box>
      </TouchableHighlight>
    </View>
  );
}
