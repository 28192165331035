import { View, Text, Modal, Pressable, Dimensions, StyleSheet } from 'react-native'
import React from 'react'
import { Portal } from 'react-native-portalize'
import WebView from 'react-native-webview'

interface ModalWebViewProps {
    isModalVisible: boolean
    closeModal: () => void
    uri: string
}

const screenHeight = Dimensions.get('window').height;

const ModalWebView: React.FC<ModalWebViewProps> = ({
    closeModal, isModalVisible, uri
}) => {
  return (
    <Portal>
        <Modal
            visible={isModalVisible}
            onRequestClose={closeModal}
            animationType="slide"
            transparent={true}
        >
            <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)', justifyContent: 'center' }}>
            <View style={{ height: screenHeight * 0.95, backgroundColor: 'white', borderRadius: 10, overflow: 'hidden' }}>
                <WebView cacheEnabled={true} source={{ uri }} style={{ flex: 1 }} />
                <Pressable onPress={closeModal} style={{ padding: 10, alignItems: 'center', backgroundColor: '#fff' }}>
                    <Text style={{ color: '#004c35', fontSize: 18 }}>X Cerrar</Text>
                </Pressable>
            </View>
            </View>
        </Modal>
    </Portal>
  )
}


export default ModalWebView