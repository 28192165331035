import {
  useNavigation,
  DrawerActions,
  useRoute,
  RouteProp,
} from "@react-navigation/native";
import { StackHeaderProps, StackNavigationProp } from "@react-navigation/stack";
import {
  Box,
  HStack,
  Text,
  IconButton,
  Stack,
  Menu,
  Pressable,
  Button,
  Image,
} from "native-base";
import { StyleSheet } from "react-native";
import Octicons from "@expo/vector-icons/Octicons";
import { Platform } from "react-native";
import React from 'react';

// @ts-ignore
import logo from "../../assets/logo-header.png";
import { RootStackParamList } from "../navigator";
import { useAppContext } from "src/contexts/app-context";

export function Header({ options, back }: StackHeaderProps) {
  const { user, token } = useAppContext();
  const route = useRoute<RouteProp<RootStackParamList>>();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const title: any =
    options.headerTitle !== undefined
      ? options.headerTitle
      : options.title !== undefined
      ? options.title
      : route.name;
  let colorHeader = "gray.50";
  let colorText = "black";

  //colores del header
  switch (route.name) {
    case "tramites":
    case "tramite-detail":
    case "editar-perfil":
      colorHeader = "#0087ff";
      colorText = "white";
      break;
    case "noticias":
    case "noticia-detail":
      colorHeader = "#800080";
      colorText = "white";
      break;
    case "eventos":
      colorHeader = "#ff6600";
      colorText = "white";
      break;
    case "evento-detail":
      colorHeader = "#ff6600";
      colorText = "white";
      break;
    case "vecinos":
      colorHeader = "#00cc99";
      colorText = "white";
      break;
    case "vecino-detail":
      colorHeader = "#00cc99";
      colorText = "white";
      break;
    case "reclamos":
    case "problema":
    case "tipo-problema":
    case "mis-reclamos":
    case "reclamos-details":
      colorHeader = "#e60000";
      colorText = "white";
      break;
    case "servicios":
    case "servicio-detail":
      colorHeader = "#ffc61a";
      colorText = "white";
      break;
    case "turismo":
      colorHeader = "#ffe066";
      colorText = "white";
      break;
    default:
      break;
  }

  return (
    <>
      <HStack
        bg="#F6FFF8"
        px="1"
        py="3"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        shadow={1}
        zIndex={9999}
      >
        <HStack alignItems="center">
          {Platform.OS !== "web" && (
            <IconButton
              icon={<Octicons size={30} name="three-bars" color="#004C35" />}
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
          )}
        </HStack>
        <HStack alignItems="center" flex={1} justifyContent="center">
          {Platform.OS !== "web" && (
            <Image
              source={logo}
              style={styles.logo}
              alt="muncipalidad corrientes logo"
            />
          )}
        </HStack>
        {route.name !== "login" && (
          <Box px="4" py="3" mr="0" rounded="full">
            <Menu
              ml="2"
              w="190"
              trigger={(triggerProps) => {
                return (
                  <Pressable
                    accessibilityLabel="More options menu"
                    {...triggerProps}
                  >
                    <Octicons name="person-fill" size={26} color="black" />
                  </Pressable>
                );
              }}
            >
              <Stack direction="column" mt="1" mx="2">
                {user && (
                  <Box alignSelf="center" mb="3">
                    <Text
                      textAlign="left"
                      color="#004c35"
                      fontWeight="medium"
                      fontSize={16}
                    >
                      {`${user.lastname}, ${user.name}`}
                    </Text>
                    <Text color="#004c35">{user.email}</Text>
                  </Box>
                )}
                {route.name !== "editar-perfil" ? (
                  <Menu.Item
                    _text={{
                      color: Boolean(user) ? "#004c35" : "pink.700",
                      fontWeight: "medium",
                      fontSize: 14,
                      textAlign: "center",
                    }}
                    rounded="xl"
                    bg={Boolean(user) ? "emerald.200" : "pink.200"}
                    onPress={() =>
                      navigation.navigate(
                        Boolean(token) ? "editar-perfil" : "login"
                      )
                    }
                  >
                    {Boolean(token) ? "Editar Perfil" : "Iniciar Sesión"}
                  </Menu.Item>
                ) : null}
              </Stack>
            </Menu>
          </Box>
        )}
      </HStack>
      <HStack
        px="3"
        pt={route.name != "home" ? 4 : 0}
        bg="white"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        {route.name === "noticia-detail" || route.name === "evento-detail" ? (
          <HStack alignItems="start" flex={1} justifyContent="start">
            <></>
          </HStack>
        ) : (
          <HStack width={"100%"} alignItems="start" justifyContent="start">
            {back &&
            Platform.OS !== "web" &&
            route.name !== "login" ? (
              <Text
                color="#004C35"
                fontSize={"xl"}
                fontWeight="bold"
                maxW={"90%"}
              >
                {title}
              </Text>
            ) : null}
          </HStack>
        )}
        <HStack
          alignItems="center"
          position="absolute"
          right={0}
          top={0}
          mr={3}
          mt={0.4}
        >
          {back && Platform.OS !== "web" ? (
            <Button
              colorScheme="pink"
              paddingLeft={3}
              paddingRight={3}
              py={2}
              mt={2}
              onPress={function () {
                const state = navigation.getState();
                const previousRoute = state.routes[state.index - 1];
                if (previousRoute.name == "reclamos") {
                  return navigation.navigate("home");
                } else {
                  return navigation.goBack();
                }
              }}
            >
              <Text color="white">Atrás</Text>
            </Button>
          ) : null}
        </HStack>
      </HStack>
    </>
  );
}

const styles = StyleSheet.create({
  logo: {
    width: "90%",
    height: 50,
    resizeMode: "contain",
  },
});
