import React, { useState } from "react";
import { useReclamos } from "src/features/mis-reclamos/queries/use-reclamos";
import {
  Center,
  Button,
  Box,
  Text,
  Select,
  HStack,
  View,
  FlatList,
} from "native-base";
import { Reclamo } from "./reclamos";
import Octicons from "@expo/vector-icons/Octicons";
import { Loading } from "src/components/loading";
import { useAppContext } from "src/contexts/app-context";
import { ReclamosEmptyAlert } from "./reclamos-empty-alert";
import { Pagination } from "./pagination";
import { Platform } from "react-native";
import { getHeightFromPercentage } from "src/utils";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "src/navigator";
import { useNavigation } from "@react-navigation/native";
import { CustomButton } from "src/components/button";

export function ReclamosList() {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { token } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setTotalItems] = useState(5);
  const { data, isLoading } = useReclamos(token);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedReclamos = data?.data.slice(startIndex, endIndex);

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const displayReclamos = () => {
    setTotalItems(() => itemsPerPage + 5);
  };

  return (
    <>
      {Platform.OS !== "web" && (
        <Box alignSelf="center" marginX={3}>
          <HStack space={2} alignItems="center">
            <Text color="#004C35" fontSize="lg" textAlign="center">
              Seguimiento de reclamos hechos
            </Text>
          </HStack>
          <Box flexDirection="row" m="auto" mt={1}>
            <Select
              flex={1}
              placeholder="Números de Reclamos"
              _selectedItem={{
                bg: "teal.600",
              }}
              mt={1}
              pr="4"
              dropdownIcon={
                <Box mr="3">
                  <Octicons name="search" color="#004C35" size={25} />
                </Box>
              }
            >
              {data?.data.map((reclamo: any) => (
                <Select.Item
                  key={reclamo.id_contacto}
                  label={`${reclamo.descripcion} - ${reclamo.id_contacto}`}
                  value={reclamo.id_contacto}
                  onPress={() => {
                    navigation.navigate("reclamos-details", {
                      id: reclamo.id_contacto,
                    });
                  }}
                />
              ))}
            </Select>
          </Box>
        </Box>
      )}
      {!isLoading ? (
        <>
          {displayedReclamos?.length > 0 ? (
            <View
              px="4"
              bg="white"
              mt={3}
              mb={Platform.OS === "ios" ? "40" : "16"}
            >
              {Platform.OS !== "web" && (
                <CustomButton
                  title={"Nuevo Reclamo"}
                  icon={"plus"}
                  onPress={() => navigation.navigate("tipo-problema")}
                  key={"addReclamo"}
                  disabled={false}
                  color={"red"}
                  size={20}
                  addStyle={{
                    alignSelf: "flex-end",
                    paddingHorizontal: 5,
                    borderRadius: 10,
                    backgroundColor: "white",
                    borderWidth: 1,
                    borderColor: "red",
                  }}
                  styleText={{
                    color: "red",
                    fontWeight: "normal",
                    fontSize: 12
                  }}
                />
              )}
              <FlatList
                data={displayedReclamos}
                renderItem={({ item }) => <Reclamo reclamos={item} />}
                keyExtractor={(item, index) => index.toString()}
                scrollEnabled={false}
              />
              {Platform.OS !== "web" ? (
                <Button
                  colorScheme="error"
                  _text={{
                    fontSize: 16,
                    fontWeight: "semibold",
                    color: "white",
                  }}
                  w="60%"
                  alignSelf="center"
                  mt="6"
                  onPress={() => displayReclamos()}
                >
                  Ver mas
                </Button>
              ) : (
                <Pagination
                  currentPage={currentPage}
                  prevPage={prevPage}
                  nextPage={nextPage}
                  totalPages={data?.data.length / itemsPerPage}
                />
              )}
            </View>
          ) : (
            <ReclamosEmptyAlert />
          )}
        </>
      ) : (
        <Center h={getHeightFromPercentage(60)}>
          <Loading />
        </Center>
      )}
    </>
  );
}
