import { Box, Input, VStack } from "native-base";
import Octicons from "@expo/vector-icons/Octicons";
import { StyleSheet } from "react-native";
export type SearchBarProps = {
  color: string;
  onChangeQueryParam: (query: string) => void;
};

export function SearchBar({ onChangeQueryParam }: SearchBarProps) {
  return (
    <VStack style={styles.column}>
      <Input
        w="100%"
        placeholder="Buscar"
        placeholderTextColor="#667085"
        fontSize={15}
        color="#667085"
        InputLeftElement={
          <Box
            w="10"
            justifyContent="center"
            alignItems="center"
            backgroundColor=""
            h="100%"
          >
            <Octicons name="search" color="#667085" size={20} />
          </Box>
        }
        onChangeText={onChangeQueryParam}
      />
    </VStack>
  );
}

const styles = StyleSheet.create({
  column: {
    alignItems: "center",
    justifyContent: "space-around",
    height: 60,
    marginHorizontal: 10
  },
});
