
import {
    Center,
    Text,
    Button
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from 'src/navigator';


export function ReclamosEmptyAlert() {
    const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

    return (
        <Center marginTop={'12'} flex={1} mb={70}>
            <MaterialCommunityIcons name="archive-alert-outline" size={150} color="#004c35" />
            <Text
                fontSize={{ base: "2xl", lg: "lg", xl: "xl", }}
                w={{ base: "95%", lg: "56", xl: "56" }}
                textAlign="center"
                fontWeight="semibold"
                color="#004c35"
                mb="3"
                lineHeight={"xs"}
                letterSpacing={"sm"}
            >
                Todavia no has hecho ningun reclamo, si desea hacer un reclamo entre aqui
            </Text>
            <Button
                variant="solid"
                justifyContent="space-evenly"
                colorScheme="none"
                bg="#004c35"
                _hover={{
                    bg: "#045c41"
                }}
                w="48"
                mt="4"
                leftIcon={<FontAwesome5 name="hammer" pl="2" size={24} color="white" />}
                onPress={() => navigation.navigate("tipo-problema")}
            >
                <Text pl="3" color="white" fontWeight="semibold">Nuevo Reclamo</Text>
            </Button>
        </Center>
    )
}