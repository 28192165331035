import { useCallback, useEffect, useState } from 'react';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import { Ionicons, Octicons } from '@expo/vector-icons';
import { NativeBaseProvider } from 'native-base';

import { Navigator } from './navigator';
import { AppContextProvider } from './contexts/app-context';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Metadata } from './metadata';
import { Host } from 'react-native-portalize';

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

export function Main() {
  const [appIsReady, setAppIsReady] = useState(false);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  useEffect(() => {
    async function prepare() {
      try {
        // Pre-load fonts, make any API calls you need to do here
        await Font.loadAsync({
          ...Ionicons.font,
          ...Octicons.font,
        });
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppIsReady(true);
      }
    }

    prepare();
  }, []);

  if (!appIsReady) {
    return null;
  }

  return (
    <NativeBaseProvider>
    <AppContextProvider>
        <SafeAreaView
            style={{ flex: 1, width: '100%', height: '100%' }}
            onLayout={onLayoutRootView}
            >
            <Metadata />
            <Host>
              <Navigator />
            </Host>
          </SafeAreaView> 
    </AppContextProvider>
    </NativeBaseProvider>
  );
}
