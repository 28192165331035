import { Box, Center, Stack, Text } from "native-base";
import { useAppContext } from "src/contexts/app-context";
import { useConfig } from "src/features/reclamos/queries/use-config";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "src/navigator";
import Agente from "../../../../assets/agente.png";
import Arbol from "../../../../assets/arboles-2.png";
import Basura from "../../../../assets/bolsa-de-basura.png";
import Construccion from "../../../../assets/construccion-1.png";
import Etapa from "../../../../assets/etapa-1.png";
import Carretera from "../../../../assets/la-carretera-1.png";
import Alumbrado from "../../../../assets/luz-de-la-calle-1.png";
import Mascotas from "../../../../assets/mascotas-1.png";
import Auto from "../../../../assets/no-estacionar-1.png";
import Parque from "../../../../assets/parque-1.png";
import Policia from "../../../../assets/policia-1.png";
import Salud from "../../../../assets/seguro-de-salud-1.png";
import Sendero from "../../../../assets/sendero-1.png";
import Tienda from "../../../../assets/tienda-1.png";
import { ProblemaItem } from "../components/TypeProblems";
import { ArrowBack } from "src/components/arrow-back";
import { Platform } from "react-native";
import { ScreensHeader } from "src/components/screens-header";
import { LayoutAuthenticated } from "src/components/layout-authenticated";
import AuthGuard from "src/guard/Guard";
import { Loading } from "src/components/loading";

export const TipoProblemaScreen = () => {
  const { token } = useAppContext();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const { data: config } = useConfig(token);
  const Imagenes: Record<number, any> = {
    0: Alumbrado,
    1: Mascotas,
    2: Arbol,
    3: Sendero,
    4: Carretera,
    5: Tienda,
    6: Construccion,
    7: Basura,
    8: Parque,
    9: Agente,
    10: Salud,
    11: Policia,
    12: Etapa,
    13: Auto,
  };

  function handleGrupoProblemaChanged(id_grupo_problema: any) {
    navigation.navigate("problema", {
      id_grupo_problema,
    });
  }

  return (
    <AuthGuard>
      <LayoutAuthenticated>
        <ArrowBack route="home" />
        {Platform.OS === "web" && (
          <ScreensHeader
            title="Nuevo Reclamo"
            description="Seleccioná el tipo de reclamo"
          />
        )}
        {Platform.OS !== "web" && (
          <Stack bg="white">
            <Text color="#004C35" fontSize="md" ml={3} maxWidth={300}>
              Seleccioná el tipo de reclamo
            </Text>
          </Stack>
        )}
        <Center mb={Platform.OS === "ios" ? '40' : '24'} bg="white">
          <Box
            mx={Platform.OS !== "web" ? 1 : 0}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            mt={Platform.OS !== "web" ? "0" : "10px"}
            pt={Platform.OS !== "web" ? 3 : 0}
            backgroundColor={Platform.OS !== "web" ? "white" : "transparent"}
          >

            {!config || !config.problemas || config.problemas.length <= 0 ? (
              <Center mt={12} h={"full"}>
                <Loading />
              </Center>
            ):(
              config?.problemas?.map((tipoProblema: any, index: number) => (
                <ProblemaItem
                  key={index}
                  tipoProblema={tipoProblema}
                  onPress={() => {
                    handleGrupoProblemaChanged(tipoProblema.id_grupo_problema);
                  }}
                  imagen={Imagenes[index]}
                />
              ))
            )}
          </Box>
        </Center>
      </LayoutAuthenticated>
    </AuthGuard>
   
  );
};
