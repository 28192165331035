import * as React from "react";
import { Text, TouchableOpacity, StyleSheet, ViewStyle, TextStyle } from "react-native";
import { Entypo } from "@expo/vector-icons";

interface CustomButtonProps {
  title: string;
  onPress: () => void;
  icon: keyof typeof Entypo.glyphMap;
  color: string;
  addStyle: ViewStyle;
  disabled: boolean;
  styleText?: TextStyle;
  size?: number;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  title,
  onPress,
  icon,
  color,
  addStyle,
  disabled,
  styleText,
  size,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{ ...styles.button, ...addStyle }}
      disabled={disabled}
    >
      <Entypo name={icon} size={size ? size : 25} color={color} />
      {title.length > 0 && (
        <Text
          style={styleText ? { ...styles.text, ...styleText } : styles.text}
        >
          {title}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 40,
    minWidth: 40,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontWeight: "bold",
    fontSize: 16,
    color: "white",
    marginLeft: 10,
  },
});
