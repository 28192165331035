import { useNoticias } from 'src/features/noticias/queries/use-noticias';
import {
  Box,
  Center,
  Divider,
  FlatList,
  HStack,
  Image,
  Text,
  VStack,
} from 'native-base';
import { Loading } from 'src/components/loading';
import { TouchableHighlight, Platform } from 'react-native';
import dayjs from 'dayjs';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from 'src/navigator';
import { useState } from 'react';

export function LatestNewsList() {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [page, setPage] = useState<number>(1);
  const {
    data: noticias,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useNoticias(page);

  const newsItemKeysExtractor = (item: any, index: number) => {
    return index.toString();
  };

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage({ pageParam: page });
      setPage(page + 1);
    }
  };

  const onPressNewsItem = (item: any) => {
    navigation.navigate('noticia-detail', {
      title: item.title,
      noticia: {
        title: item.title,
        image_url: item.image_url,
        fecha: item.fecha,
        body_value: item.body_value,
        link_web: item.link_web,
      },
    });
  };

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <FlatList
      // mb={32}
      data={noticias?.pages.flat()}
      bg="contrastThreshold"
      keyExtractor={newsItemKeysExtractor}
      contentContainerStyle={{ paddingBottom: 100 }}
      backgroundColor={'white'}
      renderItem={({ item }: any) => (
        <TouchableHighlight
          key={item.nid}
          underlayColor="transparent"
          onPress={() => onPressNewsItem(item)}
        >
          {Platform.OS !== "web" ? (
           <Box shadow="1" w="95%" m="auto" my={2} borderRadius={2} backgroundColor="white">
              <HStack alignItems="center" backgroundColor="white" borderRadius={2} 
              maxH="auto" minH={115}>
                <Image
                  size="lg"
                  height="full"
                  source={{ uri: item.thumbnail }}
                  mr="1"
                  borderTopLeftRadius="sm"
                  borderBottomLeftRadius="sm"
                  alt={item.title}
                />
                <VStack w="full" ml="2" flex={1} my={2}>
                  <Text bold fontSize="md" flex={1} color="#004C35" numberOfLines={3} lineHeight={"xs"}>
                    {item.title}
                  </Text>
                  <Text italic fontSize={"sm"} numberOfLines={1}  flex={1}>
                    {dayjs(item.fecha).format(
                      'dddd, DD [de] MMMM [de] YYYY, H:mm'
                    )}
                  </Text>
                </VStack>
              </HStack>
              <Divider />
            </Box>
          ) : (
            <Box>
              <HStack alignItems="center" p="3">
                <Image
                  size={24}
                  source={{ uri: item.thumbnail }}
                  mr="1"
                  borderRadius="sm"
                  alt={item.title}
                />
                <VStack w="70%" ml="2">
                  <Text bold fontSize="sm">
                    {item.title}
                  </Text>
                  <Text italic>
                    {dayjs(item.fecha).format(
                      'dddd, DD [de] MMMM [de] YYYY, H:mm'
                    )}
                  </Text>
                </VStack>
              </HStack>
              <Divider />
            </Box>
          )}
        </TouchableHighlight>
      )}
      onEndReachedThreshold={0}
      onEndReached={loadMore}
      ListFooterComponent={isFetchingNextPage ? <Loading /> : null}
      persistentScrollbar={true}
    />
  );
}
