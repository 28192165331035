import { useInfiniteQuery } from '@tanstack/react-query';

async function getNoticias({ pageParam = 0 }) {
  const _limit = 10;
  const _offset = pageParam * _limit;

  const res = await fetch(
    `${process.env.EXPO_PUBLIC_WEB_API_URL}/v1.0/noticias/${_offset}/${_limit}`
  );

  const data = await res.json();
  return data;
}

export function useNoticias(page: number) {
  return useInfiniteQuery(['noticias'], getNoticias, {
    getNextPageParam: (lastPage) => {
      if (lastPage.length >= 10) {
        return page;
      }
    },
  });
}
