import { Dimensions, ImageSourcePropType, Linking, Platform, StyleSheet, Modal as ModalNative } from "react-native";
import {
  Center,
  Stack,
  Heading,
  Link,
  View,
  Image,
  Box,
  Text,
  ScrollView,
  Modal,
  Spinner,
  Pressable,
} from "native-base";
import { HomeCards } from "../components/home-cards";

import { UtilLinks } from "../components/utils-links";
import { useAppContext } from "src/contexts/app-context";
import { ScreensHeader } from "src/components/screens-header";
import { LayoutAuthenticated } from "src/components/layout-authenticated";
import { HomeCardsNative } from "../components/home-cards-native";
import { RootStackParamList } from "src/navigator";

import Reclamos from "../../../../assets/reclamos.png";
import MisReclamos from "../../../../assets/mis_reclamos.png";
import TransporteSeguro from "../../../../assets/ts.png";
import App from "../../../../assets/app.png";
import Impuestos from "../../../../assets/impuestos.png";
import Carretera from "../../../../assets/la-carretera-1.png";
import Noticias from "../../../../assets/noticias-mobile.png";
import ReclamosMobile from "../../../../assets/reclamos-mobile.png";
import Tramites from "../../../../assets/tramites-mobile.png";
import Eventos from "../../../../assets/eventos-mobile.png";
import Vecinos from "../../../../assets/vecinos-mobile.png";
import Transporte from "../../../../assets/transporte_seguro.png";
import Servicios from "../../../../assets/servicios-mobile.png";
import Turismo from "../../../../assets/turismo-mobile.png";
import { LoginScreen } from "src/features/login/screens/login.screen";
import { LoginScreen as LoginScreenNative } from "src/features/login/screens/login.screen.native";
import * as Updates from 'expo-updates';
import { useEffect, useState } from "react";
import { Portal } from "react-native-portalize";
import { WebView } from 'react-native-webview';
import ModalWebView from "../components/modal-web-view-native";

export interface HomeCardInfo {
  titulo: string;
  descripcion: string;
  imagen: ImageSourcePropType | undefined;
  path: keyof RootStackParamList;
}

export function HomeScreen() {

  const [isModalVisible, setModalVisible] = useState(false);
  const screenHeight = Dimensions.get('window').height;

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const { token } = useAppContext();

  const handleImpuestos = async () => {
    Linking.openURL("https://acor.gob.ar/");
  };
  const handleTramites = async () => {
    Linking.openURL("https://ciudaddecorrientes.gov.ar/tramites");
  };
  const handleInfracciones = async () => {
    Linking.openURL(
      "https://ciudaddecorrientes.gov.ar/consulta-de-infracciones"
    );
  };

  const webCards: HomeCardInfo[] = [
    {
      titulo: "Nuevo Reclamo",
      descripcion: "Hace reclamos y sugerencias en la ciudad",
      imagen: Reclamos,
      path: Boolean(token) ? "tipo-problema" : "login",
    },
    {
      titulo: "Mis Reclamos",
      descripcion: "Nuevo Reclamo",
      imagen: MisReclamos,
      path: Boolean(token) ? "mis-reclamos" : "login",
    },
    {
      titulo: "Mi Transporte Seguro",
      descripcion: "Consultá si tu medio de transporte se encuentra habilitado",
      imagen: TransporteSeguro,
      path: "transporte",
    },
  ];

  const nativeCards: HomeCardInfo[] = [
    {
      titulo: "Noticias",
      descripcion: "Últimas noticias sobre la MCC.",
      imagen: Noticias,
      path: "noticias",
    },
    {
      titulo: "Reclamos",
      descripcion: "Accedé a tus reclamos.",
      imagen: ReclamosMobile,
      path: Boolean(token) ? "mis-reclamos" : "login",
    },
    {
      titulo: "Mi Transporte Seguro",
      descripcion: "Consultá si tu medio de transporte se encuentra habilitado",
      imagen: Transporte,
      path: "transporte",
    },
    {
      titulo: "Trámites",
      descripcion: "Información sobre trámites",
      imagen: Tramites,
      path: "tramites",
    },
    {
      titulo: "Eventos",
      descripcion: "Calendario de actividades",
      imagen: Eventos,
      path: "eventos",
    },
    {
      titulo: "Vecinos",
      descripcion: "Enterate cómo acercarte a nosotros",
      imagen: Vecinos,
      path: "vecinos",
    },
    {
      titulo: "Servicios",
      descripcion: "Mirá lo que te ofrece la Ciudad",
      imagen: Servicios,
      path: "servicios",
    },
  ];

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const checkForUpdates = async () => {
      try {
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
          setIsUpdating(true);
          await Updates.fetchUpdateAsync();

          setTimeout(async() => {
            setIsUpdating(false); 
            await Updates.reloadAsync();
          }, 2000);
          
        }
      } catch (e) {
        console.error(e);
      }
    };

    checkForUpdates();
  }, []);

  return (
    <LayoutAuthenticated>
      {token || token !== undefined || token !== null ? (
        <View padding={2} minH={"full"}>
          <ScreensHeader
            title="Inicio"
            description="Realiza y accede a tus reclamo o tramites"
          />
          {Platform.OS === "web" ? (
            <Center>
              <Stack
                direction={{ base: "column", md: "row", lg: "row", xl: "row" }}
                space={{ base: 0, lg: 6, xl: 6 }}
                flexWrap="wrap"
              >
                <HomeCards webCardInfo={webCards} />
              </Stack>
              <Heading textAlign="center" size="lg" mt="10" color="#c75786">
                Links Utiles
              </Heading>
              <Stack
                direction={{ base: "column", md: "row", lg: "row", xl: "row" }}
                justifyContent="center"
                mt="3"
                space={6}
                flexWrap="wrap"
              >
                <UtilLinks
                  title="Guia de tramites"
                  image={App}
                  handleClick={handleTramites}
                />
                <UtilLinks
                  title="Impuestos"
                  image={Impuestos}
                  handleClick={handleImpuestos}
                />
                <UtilLinks
                  title="Consulta de infracciones"
                  image={Carretera}
                  handleClick={handleInfracciones}
                />
              </Stack>
            </Center>
          ) : (
            <ScrollView bg="white" mb={Platform.OS === "ios" ? 40 : 24}>

              {isUpdating && (
                <Portal>
                  <Modal isOpen={isUpdating} onClose={() => {}} closeOnOverlayClick={false}>
                    <Modal.Content>
                      <Modal.Body>
                        <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={2}>
                          Actualizando la app
                        </Text>
                        <Text textAlign="center" mb={4}>
                          Aguarda un momento, estamos actualizando la aplicación
                        </Text>
                        <Spinner
                          size="sm"
                          color="green.600"
                          accessibilityLabel="Actualizando..."
                          mt={2}
                          mb={2}
                        />
                      </Modal.Body>
                    </Modal.Content>
                  </Modal>
                </Portal>
              )}

              <HomeCardsNative nativeCardInfo={nativeCards} />
              

              <Pressable flex={1} onPress={openModal}>
                {({ isPressed }) => (
                  <Stack
                    style={{
                      transform: [{
                      scale: isPressed ? 0.98 : 1,
                    }]}}
                    w={"100%"}
                    minH={78}
                    maxH="auto"
                    bg={isPressed ? "gray.100" : "white"} // Cambia el color cuando está presionado
                    rounded="md"
                    mt="3"
                    shadow={isPressed ? 2 : 1} // Cambia la sombra cuando está presionado
                    direction="row"
                    alignItems="center"
                    justifyContent="space-evenly"
                    mb={1}
                  >
                    <View>
                      <Image
                        source={Turismo}
                        w={60}
                        h={60}
                        alt="utiles image"
                        alignItems="center"
                        justifyContent="center"
                      />
                    </View>
                    <Box w="60%">
                      <Text fontSize="2xl" fontWeight="semibold" color="#004c35">
                        Turismo
                      </Text>
                      <Text fontSize="sm" fontWeight="normal" color="#004c35">
                        Conocé la ciudad
                      </Text>
                    </Box>          
                  </Stack>
                )}
              </Pressable>
              
              <ModalWebView 
                closeModal={closeModal}
                isModalVisible={isModalVisible}
                uri="https://visitcorrientes.tur.ar"
                key={"https://visitcorrientes.tur.ar"}
              />

            </ScrollView>
          )}
        </View>
      ) : Platform.OS === "web" ? (
        <LoginScreen />
      ) : (
        <LoginScreenNative />
      )}
    </LayoutAuthenticated>
  );
}
